import React, { useState } from "react";
import logoBlue from "../assets/img/logo_ref193_blue.png";
import { HiMenuAlt3, HiX } from "react-icons/hi";
import { Link, NavLink } from "react-router-dom";
import { Popover } from "antd";

import Modal from "./Modal";

import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { BiMailSend } from "react-icons/bi";

import menuItems from "../data/db.json";

const whatsAppContent = (
  <div>
    <p className="text-sm font-light">Написать на WhatsApp</p>
  </div>
);

const Navbar = () => {
  const [show, setShow] = useState(false);
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="flex items-center justify-between px-4 py-2 md:flex-col xl:flex-row">
      {/* logotype */}
      <Link to="/">
        <div className="w-[200px] p-2 lg:w-[300px]">
          <img src={logoBlue} alt="РЕФ193.рф" className="h-full w-full" />
        </div>
      </Link>

      <div className="text-md mx-2 my-3 hidden flex-col border-r border-l border-blue-900 px-2  md:flex md:w-[200px]">
        <div className="flex flex-row items-center justify-start gap-3 text-blue-900">
          <FaPhoneAlt />
          <a
            href="tel:89126220099"
            className="text-blue-900 transition-colors duration-200 hover:text-sky-400"
          >
            +7 912 622 00 99
          </a>
        </div>
        <div className="flex flex-row items-center justify-start gap-3 text-blue-900">
          <BiMailSend size={25} />
          <a
            href="mailto:ref193@list.ru"
            className="text-blue-900 transition-colors duration-200 hover:text-sky-400"
          >
            ref193@list.ru
          </a>
        </div>
      </div>

      {/* menu */}
      <nav className="flex">
        {menuItems.menuItems.map((item) => (
          <ul className="hidden flex-row md:flex">
            <NavLink to={item.link}>
              <li
                key={item.id}
                className="btn md:text-md group flex items-center justify-center whitespace-nowrap bg-transparent px-4 text-center text-lg text-white"
              >
                <span class="relative text-blue-900 after:absolute after:bottom-0 after:left-0 after:block after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 after:bg-blue-900 after:transition-transform after:duration-500 after:ease-out after:content-[''] after:group-hover:origin-bottom-left after:group-hover:scale-x-100">
                  {item.title}
                </span>
              </li>
            </NavLink>
          </ul>
        ))}
      </nav>

      <Popover content={whatsAppContent}>
        <div className="flex cursor-pointer flex-row items-center justify-start gap-3 text-blue-900 hover:text-sky-400">
          <a
            href="https://wa.link/yo8qpr"
            className="text-blue-900 transition-colors duration-200 hover:text-sky-400"
            target="_blank"
            rel="noreferrer"
          >
            <FaWhatsapp size={30} />
          </a>
        </div>
      </Popover>

      {/* mobile menu dropdown */}
      <div
        onClick={handleNav}
        className={
          nav
            ? "absolute left-0 top-0 z-50 flex w-full flex-col bg-sky-400 px-4 pl-7 duration-300 ease-in-out md:hidden"
            : "absolute left-0 top-[-100%] z-50 flex w-full flex-col bg-sky-400 px-4 pl-7 duration-300 ease-in-out md:hidden"
        }
      >
        {menuItems.menuItems.map((item) => (
          <ul className="">
            <NavLink to={item.link}>
              <li
                key={item.id}
                className="border-b p-4 text-center text-xl text-sky-50 hover:bg-sky-100/50"
              >
                {item.title}
              </li>
            </NavLink>
          </ul>
        ))}
        <div className="w-full">
          <button
            onClick={() => setShow(true)}
            className="mx-auto mt-4 mb-5 w-full rounded-md border border-blue-900 bg-transparent px-3 py-2 font-semibold text-blue-900 transition-colors duration-300 hover:bg-blue-900 hover:text-sky-50"
          >
            Оставить заявку
          </button>
        </div>
      </div>

      {/* burger menu */}
      <div onClick={handleNav} className="z-50 block text-blue-900 md:hidden">
        {nav ? (
          <HiX
            size={30}
            className="absolute top-5 right-5 transition-opacity ease-in-out"
          />
        ) : (
          <HiMenuAlt3 size={30} className="transition-opacity ease-in-out" />
        )}
      </div>
      <Modal onClose={() => setShow(false)} show={show} />
    </div>
  );
};

export default Navbar;
