import React from "react";
import Auto from "../Auto";

const Autopark = () => {
  return (
    <div className="container relative mx-auto  px-4 py-2 sm:px-6 lg:px-8 lg:py-14 ">
      <h3 className="p-1 text-center font-roboto text-3xl font-thin text-sky-50">
        Автопарк:
      </h3>
      <h5 className="p-1 text-center font-roboto text-lg font-thin text-sky-50">
        (Автопарк компании в стадии пополнения)
      </h5>

      <Auto />
    </div>
  );
};

export default Autopark;
