import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";
import { init, sendForm } from "emailjs-com";
init("nqYDw2PzTyIlWqbwY");

const Modal = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const [statusMessage, setStatusMessage] = useState("");

  const onSubmit = (data, e) => {
    e.preventDefault();
    generateContactNumber();
    const statusMessage = document.querySelector(".status-message");
    sendForm("service_j8nc61c", "ref193", "#contact-form").then(
      function (response) {
        setStatusMessage("Сообщение отправлено!");
        statusMessage.className = "status-message success";
        setTimeout(() => {
          statusMessage.className = "status-message";
        }, 5000);
      },
      function (error) {
        setStatusMessage("Не удалось отправить сообщение! Повторите");
        statusMessage.className = "status-message failure";
        setTimeout(() => {
          statusMessage.className = "status-message";
        }, 5000);
      }
    );
    reset();
    setShow(false);
    statusMessage();
  };

  const [contactNumber, setContactNumber] = useState("");

  const generateContactNumber = () => {
    const numStr = "" + ((Math.random() * 1000000) | 0);
    setContactNumber(numStr.substring(numStr.length - 6));
  };

  const [show, setShow] = useState(false);
  if (!props.show) {
    return null;
  }

  return (
    <div
      className="fixed left-0 top-0 right-0 bottom-0 z-50 flex items-center justify-center overflow-hidden bg-slate-800/50"
      onClick={props.onClose}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="relative flex h-[680px] w-[300px] flex-col items-center justify-around overflow-hidden rounded-3xl bg-sky-50 sm:w-[500px] lg:h-[550px] lg:w-[720px]"
      >
        <div className="py-3 px-5">
          <h4 className="mx-auto text-center text-2xl font-semibold lg:text-3xl">
            Получите индивидуальное <br />
            коммерческое предложение !
          </h4>
        </div>

        <p
          className={
            statusMessage
              ? "status-message success rounded-md bg-sky-400 px-3 text-white"
              : "status-message failure rounded-md bg-red-500 px-3 text-white"
          }
        >
          {statusMessage}
        </p>
        <div className="">
          <p className="text-center text-lg font-bold text-blue-900 lg:text-xl">
            Заполните форму ниже:
          </p>
          <p className="text-md mx-3 px-5 py-4 text-center text-gray-500">
            Мы перезвоним в рабочее время, чтобы уточнить детали и составить для
            вас самый выгодный план сотрудничества.
          </p>
        </div>
        <form
          id="contact-form"
          className="flex w-full flex-col items-center justify-center gap-2 px-6 py-2 lg:w-3/4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            {...register("userName", {
              required: "Поле обязательно для заполнения",
              maxLength: 20,
              minLength: {
                value: 4,
                message: "Введите минимум 4 символа",
              },
            })}
            type="text"
            placeholder="Введите имя"
            className="w-full rounded-lg py-4 px-8"
          />
          <div className="text-sm text-red-500">
            {errors?.userName && (
              <p>{errors?.userName.message || "Заполните правильно поле !"}</p>
            )}
          </div>
          <input
            {...register("userEmail", {
              required: "Заполните почтовый адрес",
              pattern: /\S+@\S+\.\S+/,
              message:
                "Введенные данные не подходит под формат электронной почты",
            })}
            type="email"
            placeholder="Введите почту"
            className="w-full rounded-lg py-4 px-8"
          />
          <div className="text-sm text-red-500">
            {errors?.userEmail && (
              <p>{errors?.userEmail.message || "Заполните адрес почты !"}</p>
            )}
          </div>
          <input
            {...register("userPhone", {
              required: "Введите номер телефона",
              maxLength: 14,
            })}
            type="tel"
            placeholder="Введите телефон"
            className="w-full rounded-lg py-4 px-8"
          />
          <div className="text-sm text-red-500">
            {errors?.userPhone && (
              <p>{errors?.userPhone.message || "Не более 14 символов!"}</p>
            )}
          </div>
          <input type="hidden" name="contact_number" value={contactNumber} />

          <input
            type="submit"
            className="cursor-pointer rounded-lg bg-blue-900 px-6 py-2 text-sky-50 transition-colors duration-150 ease-in-out hover:bg-sky-400"
            value="Получить предложение"
          />
        </form>

        <span className="mb-3 px-4 text-center text-xs text-slate-500">
          Нажимая на кнопку, вы соглашаетесь с
          <Link
            to="/privacy"
            className="ml-1 hover:text-sky-400 hover:underline"
          >
            условиями обработки персональных данных
          </Link>
        </span>
        <div
          onClick={props.onClose}
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
          className="absolute right-5 top-3 cursor-pointer "
        >
          {show ? (
            <MdOutlineClose
              size={30}
              className="rotate-90 transition-all duration-200"
            />
          ) : (
            <MdOutlineClose
              size={30}
              className="rotate-[-90] transition-all duration-200"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
