import React from "react";
import thermometer from "../../assets/img/thermometer.png";

import transport from "../../assets/icons/transport.png";
import handleCare from "../../assets/icons/handleCare.png";
import temperature from "../../assets/icons/temperature.png";
import work from "../../assets/icons/work.png";

import payment from "../../assets/icons/payment.svg";
import edo from "../../assets/icons/edo.svg";
import agreement from "../../assets/icons/agreement.svg";
import drivers from "../../assets/icons/drivers.svg";
import sanitary from "../../assets/icons/sanitary.svg";

const About = (page) => {
  return (
    <div className="container relative mx-auto w-full">
      <h3 className="p-5 text-center font-roboto text-3xl font-thin text-blue-900/80 ">
        О компании:
      </h3>

      {/* наша специализация */}
      <div className="mx-10 flex flex-row flex-wrap justify-center text-blue-900">
        <div className="basis-2/3 text-lg md:text-start md:text-xl lg:text-2xl">
          <p className="text-2xl font-bold md:text-3xl">Наша специализация -</p>
          доставка товаров, требующих соблюдения температурного режима в Сочи.
          <p className="">
            Курьерская доставка продуктов B2B, B2C в малом изотермическом
            фургоне или с рефрижератором от -18 C° до +12 C°
          </p>
        </div>
        <div className="flex basis-1/3 items-center justify-center">
          <img
            src={thermometer}
            alt=""
            className="mt-4 flex items-center justify-center md:block md:max-w-[100px] lg:max-w-[150px]"
          />
        </div>
      </div>

      {/* основная цель нашего предложения */}
      <div className="mx-10">
        <p className="py-2 text-xl text-blue-900 md:text-2xl">
          Наша основная задача – стать для Вас надежным партнером!
        </p>
        <p className="text-xl text-blue-900 md:text-2xl">
          Ваши заказы всегда будут доставлены вовремя с соблюдением необходимых
          температурных режимов – можете не сомневаться!
        </p>
      </div>

      <div className="mx-auto mt-4 justify-between px-10 lg:mt-16">
        <div className="lg:grid-col-3 grid gap-4 lg:grid-flow-col lg:grid-rows-2">
          {/* Договор: */}
          <div className="justify-center rounded-md  bg-white p-5 ">
            <div className="flex flex-row items-center gap-2">
              <img src={agreement} alt="" width={70} className="" />
              <h4 className="font-semibold">Договор:</h4>
            </div>
            <p className="mt-2 text-sm leading-6">
              Работаем по договору на оказание транспортно-логистических услуг.
              <br />
              Индивидуальный предприниматель без НДС.
              <br />
            </p>
          </div>

          {/* Способы оплаты: */}
          <div className="justify-center rounded-md   bg-white p-5 ">
            <div className="flex flex-row items-center gap-2">
              <img src={payment} alt="" width={70} className="" />
              <h4 className="font-semibold">Способы оплаты:</h4>
            </div>

            <p className="mt-2 text-sm leading-6">
              Оплатить услуги по доставке можно удобным для вас способом.
            </p>
            <p className="text-sm leading-6">
              Работаем с физическими и юридическими лицами.
            </p>
          </div>

          {/* ЭДО: */}
          <div className="justify-center rounded-md   bg-white p-5 ">
            <div className="flex flex-row items-center gap-2">
              <img src={edo} alt="" width={70} />
              <h4 className="font-semibold">
                Мы используем электронный документооборот (ЭДО):
              </h4>
            </div>

            <p className="mt-2 text-sm leading-6">
              ЭДО сокращает время, экономит бумагу, сохраняет экологию и
              ускоряет решение задач. <br />
              Работаем с:
            </p>

            <p className="text-sm">
              <a
                href="https://korusconsulting.ru/"
                className="font-semibold text-blue-900 underline-offset-4 hover:underline"
                target="_blank"
                rel="noreferrer"
              >
                КОРУС Консалтинг,
              </a>

              <a
                href="https://www.diadoc.ru/"
                className="ml-1 font-semibold text-blue-900 underline-offset-4 hover:underline"
                target="_blank"
                rel="noreferrer"
              >
                Контур Диадок,
              </a>

              <a
                href="https://sbis.ru/"
                className="ml-1 font-semibold text-blue-900 underline-offset-4 hover:underline"
                target="_blank"
                rel="noreferrer"
              >
                СБИС
              </a>
            </p>
          </div>

          {/* Опытные водители: */}
          <div className="justify-center rounded-md  bg-white p-5 ">
            <div className="flex flex-row items-center gap-2">
              <img src={drivers} alt="" width={70} />
              <h4 className="font-semibold">Опытные водители:</h4>
            </div>

            <p className="mt-2 text-sm leading-6">
              У нас работают только опытные водители-экспедиторы, которые
              бережно доставят Ваш груз до конечной точки.
            </p>
          </div>

          {/* Санитарные и технические нормы: */}
          <div className="border-sky-400 bg-white p-5 lg:row-start-1 lg:row-end-3">
            <div className="flex flex-row items-center gap-2">
              <img src={sanitary} alt="" width={70} />
              <h4 className="font-semibold">Санитарные и технические нормы:</h4>
            </div>
            <p className="mt-2 text-sm leading-6">
              Обеспечиваем соблюдение всех санитарно-гигиенических и технических
              требований, предъявляемых к перевозке грузов в температурном
              режиме..
            </p>
            <p className="mt-2 text-sm leading-6">
              Все водители имеют действующие медицинские книжки.
            </p>
            <p className="mt-2 text-sm leading-6">
              Регулярная (ежемесячная) санитарная обработка кузова, согласно
              Санитарным правилам 01 - СП 2.3.6.1066-01, подтверждается актом
              проведения дезинфекции транспортного средства.
            </p>
            <p className="mt-2 text-sm leading-6">
              Автомобиль и холодильная установка проходят регулярное техническое
              обслуживание в специализированных тех. сервисах.
            </p>
            <p className="mt-2 text-sm leading-6">
              Температурный контроль перевозки контролируется терморегистратором
              (даталоггером)
            </p>
          </div>
        </div>

        {/* про доверие */}
        <div className="mt-10 w-full rounded-xl bg-white p-5 text-blue-900 ">
          <h2 className="text-center text-2xl font-medium">
            Нам доверяют, потому что мы:
          </h2>
          <div className="mt-10 grid grid-cols-2 items-center justify-center gap-6 md:grid-cols-4">
            <div className="flex flex-col items-center">
              <div className="h-[100px] w-[100px] p-2">
                <img src={transport} alt="" className="" />
              </div>
              <div className="">
                <p className="text-md text-center lg:text-xl">
                  Специализируемся на доставке компактных грузов
                </p>
              </div>
            </div>

            <div className="flex flex-col items-center justify-center">
              <div className="h-[100px] w-[100px] p-2">
                <img src={work} alt="" className="" width={110} />
              </div>

              <p className="text-md text-center lg:text-xl">
                Предлагаем прозрачные и понятные условия работы
              </p>
            </div>

            <div className="flex flex-col items-center justify-center">
              <div className="h-[100px] w-[100px] p-2">
                <img src={handleCare} alt="" className="" />
              </div>
              <div className="">
                <p className="text-md text-center lg:text-xl">
                  Гарантируем сохранность <br />
                  грузов
                </p>
              </div>
            </div>

            <div className="flex flex-col items-center justify-center">
              <div className="h-[100px] w-[100px] p-2">
                <img src={temperature} alt="" className="" />
              </div>

              <p className="text-md text-center lg:text-xl">
                Строго соблюдаем температурный режим
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
