import React, { useState } from "react";

import logoWhite from "../assets/img/logo_circle_white.png";
import { HiMenuAlt3, HiX } from "react-icons/hi";
import { Popover } from "antd";
import { Link } from "react-scroll";

import Modal from "./Modal";

import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { BiMailSend } from "react-icons/bi";

import menuItems from "../data/db.json";

const whatsAppContent = (
  <div>
    <p className="text-xs font-light">Написать на WhatsApp</p>
  </div>
);

const Navbar = ({ pages }) => {
  const [show, setShow] = useState(false);
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="container flex items-center justify-between px-4 py-2 md:flex-col xl:flex-row">
      {/* logotype */}
      <a href="/">
        <div className="flex flex-row items-center justify-center">
          <div className="max-w-[80px] cursor-default p-2 lg:w-[80px]">
            <img
              src={logoWhite}
              alt="РЕФ193.рф"
              className="h-full w-full animate-spin"
            />
          </div>
          <div className="flex flex-col">
            <h4 className="font-['Roboto'] text-2xl font-black text-white lg:text-3xl">
              РЕФ193.<span className="font-normal">рф</span>
            </h4>
            <h6 className="font-['Roboto'] text-xs font-light text-white lg:text-sm">
              РЕФРИЖЕРАТОР В СОЧИ
            </h6>
          </div>
        </div>
      </a>
      <div className="text-md mx-2 my-3 hidden flex-col border-r border-l px-2  md:flex md:w-[200px]">
        <div className="flex flex-row items-center justify-start gap-3 text-sky-50">
          <FaPhoneAlt />
          <a
            href="tel:89667750099"
            className="whitespace-nowrap text-sky-50 transition-colors duration-200 hover:text-sky-400"
          >
            +7 966 77 500 99
          </a>
        </div>
        <div className="flex flex-row items-center justify-start gap-3 text-sky-50">
          <BiMailSend size={25} />
          <a
            href="mailto:ref193@yandex.ru"
            className="whitespace-nowrap text-sky-50 transition-colors duration-200 hover:text-sky-400"
          >
            ref193@yandex.ru
          </a>
        </div>
      </div>
      {/* menu */}
      <nav className="flex">
        {menuItems.menuItems.map((item) => (
          <ul key={item.id} className="hidden flex-row md:flex">
            <Link to={item.id} spy={true} smooth="true">
              <li
                key={item.id}
                className="btn text-md lg:text-md xl:text-md group flex cursor-pointer whitespace-nowrap bg-transparent px-4 text-white 2xl:text-lg"
              >
                <span className="relative text-white after:absolute after:bottom-0 after:left-0 after:block after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 after:bg-sky-50 after:transition-transform after:duration-500 after:ease-out after:content-[''] after:group-hover:origin-bottom-left after:group-hover:scale-x-100">
                  {item.title}
                </span>
              </li>
            </Link>
          </ul>
        ))}
      </nav>

      <Popover content={whatsAppContent}>
        <div className="flex cursor-pointer flex-row items-center justify-start gap-3 rounded-md border-l border-r px-3 py-2 text-sky-50 transition-colors duration-200 hover:bg-[#25d366] hover:text-white">
          <a
            href="https://wa.me/79126220099"
            className="flex flex-row gap-2"
            target="_blank"
            rel="noreferrer"
          >
            <span className="hidden md:block md:text-base">Связаться</span>
            <FaWhatsapp size={20} />
          </a>
        </div>
      </Popover>

      {/* mobile menu dropdown */}
      <div
        onClick={handleNav}
        className={
          nav
            ? "absolute left-0 top-0 z-50 flex w-full flex-col bg-sky-400 px-4 pl-7 duration-300 ease-in-out md:hidden"
            : "absolute left-0 top-[-100%] z-50 flex w-full flex-col bg-sky-400 px-4 pl-7 duration-300 ease-in-out md:hidden"
        }
      >
        {menuItems.menuItems.map((item) => (
          <ul key={item.id} className="">
            <Link to={item.id} spy={true} smooth="true">
              <li
                key={item.id}
                className="border-b p-4 text-center text-xl text-sky-50 hover:bg-sky-100/50"
              >
                {item.title}
              </li>
            </Link>
          </ul>
        ))}
        <div className="w-full">
          <button
            onClick={() => setShow(true)}
            className="mx-auto mt-4 mb-5 w-full rounded-md border border-blue-900 bg-transparent px-3 py-2 font-semibold text-blue-900 transition-colors duration-300 hover:bg-blue-900 hover:text-sky-50"
          >
            Оставить заявку
          </button>
        </div>
      </div>

      {/* burger menu */}
      <div onClick={handleNav} className="z-50 block text-sky-50 md:hidden">
        {nav ? (
          <HiX
            size={30}
            className="absolute top-5 right-5 transition-opacity ease-in-out"
          />
        ) : (
          <HiMenuAlt3 size={30} className="transition-opacity ease-in-out" />
        )}
      </div>
      <div className="z-50">
        <Modal onClose={() => setShow(false)} show={show} className="" />
      </div>
    </div>
  );
};

export default Navbar;
