import React from "react";
import Iframe from "react-iframe";

const Delivery = () => {
  return (
    <div className="container relative w-full ">
      <div className="bg-sky-50 p-10 ">
        <div className="p-5 text-blue-900">
          <h3 className="p-4 text-center font-roboto text-3xl font-thin text-blue-900/80">
            Зона доставки
          </h3>
          <p className="text-center">
            С маршрутами доставки вы можете ознакомится ниже в интерактивной
            карте
          </p>
        </div>
        <div className="md:h-full lg:h-full">
          <Iframe
            src="https://yandex.ru/map-widget/v1/?um=constructor%3A239a55ea7f136a56423e280defb14cdf76489ac9b3646085aa433392056709db&amp;source=constructor"
            width="100%"
            height="686"
            frameborder="0"
            className="saturate-50 hover:saturate-100"
          ></Iframe>
        </div>
      </div>
    </div>
  );
};

export default Delivery;
