import React from "react";

const routing = [
  {
    id: 1,
    routing: "Центральный Сочи",
    addition: "от Мамайки до Светланы",
    price: "от 1500 ₽",
  },
  {
    id: 2,
    routing: "Сочи-Дагомыс",
    price: "2500 ₽",
  },
  {
    id: 3,
    routing: "Сочи-Адлер",
    price: "3000 ₽",
  },
  {
    id: 4,
    routing: "Сочи-Аэропорт",
    price: "3500 ₽",
  },
  {
    id: 5,
    routing: "Сочи-Олимпийский парк",
    price: "3500 ₽",
  },
  {
    id: 6,
    routing: "Адлер-Красная Поляна",
    price: "3500 ₽",
  },
  {
    id: 7,
    routing: "Сочи-Красная Поляна",
    price: "4500 ₽",
  },
  {
    id: 8,
    routing: "Адлер-Дагомыс",
    price: "4500 ₽",
  },
  {
    id: 9,
    routing: "Междугородние перевозки",
    price: " по согласованию",
    addition: "",
  },
];

const Price = () => {
  return (
    <div className="container mx-auto px-4 py-2 sm:px-6 lg:px-8 lg:py-14 ">
      <div className="rounded-md bg-white shadow-2xl">
        <div className="p-5 text-blue-900">
          <h3 className="p-4 text-center font-roboto text-2xl font-extralight text-blue-900/80 lg:text-3xl">
            Прайс на разовую доставку груза в температурном режиме по Сочи
            <br /> (в одну точку):
          </h3>
        </div>

        <div className="flex flex-col gap-2">
          {routing.map((item) => (
            <table key={item.id}>
              <tbody className="">
                <tr className="border-1 mx-auto grid w-full max-w-4xl grid-cols-2 items-center justify-center gap-2 border p-3">
                  <td className="text-md px-2 text-start text-blue-900 md:text-xl lg:px-10">
                    {item.routing}
                    <p className="text-sm">{item.addition}</p>
                  </td>
                  <td className="text-center text-xl font-medium text-sky-400 md:text-3xl">
                    {item.price}
                  </td>
                </tr>
              </tbody>
            </table>
          ))}
          <tr className="border-1 mx-auto grid w-full max-w-4xl grid-cols-2 items-center justify-center gap-2 border p-3">
            <td className="text-md px-2 text-start text-blue-900 md:text-xl lg:px-10">
              Почасовой тариф
              <p className="text-sm">
                Минимальный заказ 2 часа <br /> (с Красной Поляной - 3 часа)
              </p>
            </td>
            <td className="text-center text-sm font-medium text-sky-400 md:text-xl">
              1500 ₽/час
              {/* <br /> (c 3го часа - 1300 ₽/час) 
              <br /> 2я поставка - 1300 ₽/час <br /> и далее <br /> */}
            </td>
          </tr>

          <div className="text-md container mt-6 max-w-4xl px-2 font-medium text-blue-900 lg:text-2xl">
            <p className="text-center">
              Каждая следующая точка разгрузки 500 руб.{" "}
            </p>
            {/* <p className="">Погрузка-разгрузка-ожидание 30 мин. - бесплатно </p>
            <p className="">с 31й минуты 500 руб./30 мин.</p> */}
          </div>

          <div className="mx-auto mt-6 max-w-4xl flex-row flex-wrap">
            <p className="mx-4 text-xs text-gray-600">
              Тарифы обновлены 9.10.2023. Не является договором публичной
              оферты.
            </p>
            <p className="mx-4 mb-2 text-xs text-gray-600">
              Цены на перевозку могут отличаться.
              <span className="ml-2 ">Для уточнения цены свяжитесь с нами</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Price;
