import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import logoCircle from "../assets/img/logo_circle.png";

import NavbarDark from "../components/NavbarDark";

const ErrorPage = () => {
  return (
    <section className=" bg-sky50">
      <motion.div
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="container mx-auto w-full px-4"
      >
        <NavbarDark />

        <div class="mx-auto mb-3 max-w-[1400px] sm:px-6 lg:mb-10 lg:px-8">
          <div class="mt-8 overflow-hidden">
            <div class="">
              <div class="mr-2 rounded-lg bg-sky-50 p-6 dark:bg-gray-800 sm:rounded-lg">
                <div className="flex items-center justify-center gap-5">
                  <span className="text-7xl text-blue-900 lg:text-[180px]">
                    4
                  </span>
                  <img
                    src={logoCircle}
                    alt=""
                    className="w-[100px] animate-spin lg:w-[180px]"
                  />
                  <span className="text-7xl text-blue-900 lg:text-[180px]">
                    4
                  </span>
                </div>
                <h1 class="mt-6 text-center text-4xl font-extrabold tracking-tight text-gray-800  sm:text-5xl">
                  К сожалению такой страницы не существует!
                </h1>
                <p class="text-normal mt-10 text-center text-lg font-medium text-gray-600 sm:text-2xl">
                  Вернитесь пожалуйста на{" "}
                  <Link
                    to="/"
                    className="text-blue-500 underline-offset-4 hover:underline"
                  >
                    главную страницу
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="h-[100px]"></div>
      </motion.div>
    </section>
  );
};

export default ErrorPage;
