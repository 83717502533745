import React from "react";
import { Carousel } from "antd";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

import avatar1 from "../assets/testimonials/avatar_yogurt_shop.jpg";
import logo1 from "../assets/testimonials/logo_yogurt_shop.png";

import avatar2 from "../assets/testimonials/avatar_ru_testo.jpg";
import logo2 from "../assets/testimonials/logo_ru_testo.png";

const Testimonials = () => {
  return (
    <div className="container relative mx-auto px-4 sm:px-6 lg:px-8">
      <Carousel
        autoplay
        className="flex min-h-[200px] items-center justify-center rounded-md bg-sky-400"
      >
        {/* Testimonial 1 */}
        <div className="flex  items-center justify-center rounded-md ">
          <div className="m-4 flex items-center justify-center rounded-lg p-2">
            <div className="flex flex-row flex-wrap justify-between ">
              <div className="mx-auto mb-6 flex w-36 items-center justify-center md:mx-0 md:w-96 lg:mb-0">
                <div className="w-[100px] lg:w-[200px]">
                  <img
                    src={logo1}
                    className="rounded-full bg-sky-50/80 p-4 shadow-md"
                    alt="avatar"
                  />
                </div>
              </div>
              <div className="relative">
                <div className="flex flex-row items-center">
                  <img
                    src={avatar1}
                    alt="Анна Литвинова"
                    width={60}
                    className="mb-2 rounded-full border-2"
                  />
                  <div className="mb-4 flex flex-col">
                    <p className="mx-4 text-lg font-semibold text-sky-50 lg:text-xl">
                      Анна Литвинова
                    </p>
                    <p className="mx-4 text-xs font-medium text-sky-50 md:text-base">
                      Директор ООО "Иогурт Шоп"
                    </p>
                  </div>
                </div>

                <div className="absolute top-[50px] left-[-70px] hidden lg:block">
                  <FaQuoteLeft size={50} className="text-sky-50/50" />
                </div>
                <p className="mx-2 mb-6 max-w-[600px] rounded-xl bg-sky-50/50 px-2 py-2 font-roboto text-xs font-light text-blue-900 shadow-sm md:text-sm lg:px-10">
                  Пользуюсь услугами РЕФ193 для доставки йогуртов нашим
                  контрагентам по Большому Сочи.
                  <br />
                  Радует, что работу компания делает «под ключ» - своевременная
                  подача автомобиля, погрузка на складе, составление маршрута,
                  сдача товара клиенту, работа с обменами и возвратной тарой,
                  грамотная работа с сопроводительной документацией.
                  <br />
                  Транспортировку осуществляют на чистом автомобиле в
                  необходимом температурном режиме, для Сочи, особенно в летний
                  период, это очень важно.
                  <br />
                  Приятно сотрудничать с профессиональными людьми, которые
                  добросовестно относятся к своей работе.
                  <br />
                  Рекомендую РЕФ193 как надежного и ответственного партнера на
                  рынке транспортных услуг.
                </p>
                <div className="absolute bottom-[-10px] right-[-50px] hidden lg:block">
                  <FaQuoteRight size={50} className="text-sky-50/50" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Testimonial 2 */}
        <div className="flex items-center justify-center rounded-md ">
          <div className="">
            <div className="m-4 flex items-center justify-center rounded-lg p-2">
              <div className="flex flex-row flex-wrap items-center justify-between">
                <div className="mx-auto mb-6 flex w-36 items-center justify-center md:mx-0 md:w-96 lg:mb-0">
                  <div className="w-[100px] lg:w-[200px]">
                    <img
                      src={logo2}
                      className="rounded-full bg-sky-50/80 p-4 shadow-md"
                      alt="avatar"
                    />
                  </div>
                </div>
                <div className="relative">
                  <div className="flex flex-row items-center">
                    <img
                      src={avatar2}
                      alt="Александра Панова"
                      width={60}
                      className="mb-2 rounded-full border-2"
                    />
                    <div className="mb-4 flex flex-col">
                      <p className="mx-4 text-lg font-semibold text-sky-50 lg:text-xl">
                        Александра Панова
                      </p>
                      <p className="mx-4 text-xs font-medium text-sky-50 md:text-base">
                        Собственник производства
                      </p>
                    </div>
                  </div>

                  <div className="absolute top-[50px] left-[-70px] hidden lg:block">
                    <FaQuoteLeft size={50} className="text-sky-50/50" />
                  </div>
                  <p className="mx-2 mb-6 max-w-[600px] rounded-xl bg-sky-50/50 px-2 py-2 font-roboto text-xs font-light text-blue-900 shadow-sm md:text-sm lg:px-10">
                    С компанией реф193 и его владельцем Александром удобно
                    работать, они пунктуальные, ответственные, к продукции
                    относится аккуратно.
                    <br />
                    Отправляя с ними свою замороженную продукцию, уверенна, что
                    она доедет в целостности и сохранности на любые расстояния.
                    <br />
                    Рекомендую!
                  </p>
                  <div className="absolute bottom-[-10px] right-[-50px] hidden lg:block">
                    <FaQuoteRight size={50} className="text-sky-50/50" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default Testimonials;
