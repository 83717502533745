import { useState } from "react";
import Modal from "../Modal";
import { motion } from "framer-motion";

const SpecialOffer = () => {
  const [show, setShow] = useState(false);

  return (
    <div className="container mx-auto px-4 py-10 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 items-center justify-center lg:grid-cols-2 ">
        <div className="col-span-1">
          <div className="space-y-5 md:space-y-4">
            <motion.h2
              initial={{ x: -100 }}
              animate={{ x: 0 }}
              className="text-2xl font-bold text-white dark:text-gray-200 lg:text-4xl"
            >
              Получите индивидуальное коммерческое предложение !
            </motion.h2>
            <p className="text-gray-300">
              Специальные условия для регулярных доставок <br />
              (от 10 точек разгрузки)
            </p>
          </div>
        </div>
        <ul className="col-span-1 mt-2 space-y-2">
          <li className="flex space-x-3">
            <svg
              className="h-6 w-6 flex-shrink-0 text-sky-50"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.1965 7.85999C15.1965 3.71785 11.8387 0.359985 7.69653 0.359985C3.5544 0.359985 0.196533 3.71785 0.196533 7.85999C0.196533 12.0021 3.5544 15.36 7.69653 15.36C11.8387 15.36 15.1965 12.0021 15.1965 7.85999Z"
                fill="currentColor"
                fillOpacity="0.1"
              />
              <path
                d="M10.9295 4.88618C11.1083 4.67577 11.4238 4.65019 11.6343 4.82904C11.8446 5.00788 11.8702 5.32343 11.6914 5.53383L7.44139 10.5338C7.25974 10.7475 6.93787 10.77 6.72825 10.5837L4.47825 8.5837C4.27186 8.40024 4.25327 8.0842 4.43673 7.87781C4.62019 7.67142 4.93622 7.65283 5.14261 7.83629L7.01053 9.49669L10.9295 4.88618Z"
                fill="currentColor"
              />
            </svg>

            <span className="text-xl text-sky-50">по Сочи </span>
          </li>

          <li className="flex space-x-3">
            <svg
              className="h-6 w-6 flex-shrink-0 text-sky-50"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.1965 7.85999C15.1965 3.71785 11.8387 0.359985 7.69653 0.359985C3.5544 0.359985 0.196533 3.71785 0.196533 7.85999C0.196533 12.0021 3.5544 15.36 7.69653 15.36C11.8387 15.36 15.1965 12.0021 15.1965 7.85999Z"
                fill="currentColor"
                fillOpacity="0.1"
              />
              <path
                d="M10.9295 4.88618C11.1083 4.67577 11.4238 4.65019 11.6343 4.82904C11.8446 5.00788 11.8702 5.32343 11.6914 5.53383L7.44139 10.5338C7.25974 10.7475 6.93787 10.77 6.72825 10.5837L4.47825 8.5837C4.27186 8.40024 4.25327 8.0842 4.43673 7.87781C4.62019 7.67142 4.93622 7.65283 5.14261 7.83629L7.01053 9.49669L10.9295 4.88618Z"
                fill="currentColor"
              />
            </svg>

            <span className="text-xl text-sky-50">по Адлеру</span>
          </li>

          <li className="flex space-x-3">
            <svg
              className="h-6 w-6 flex-shrink-0 text-sky-50"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.1965 7.85999C15.1965 3.71785 11.8387 0.359985 7.69653 0.359985C3.5544 0.359985 0.196533 3.71785 0.196533 7.85999C0.196533 12.0021 3.5544 15.36 7.69653 15.36C11.8387 15.36 15.1965 12.0021 15.1965 7.85999Z"
                fill="currentColor"
                fillOpacity="0.1"
              />
              <path
                d="M10.9295 4.88618C11.1083 4.67577 11.4238 4.65019 11.6343 4.82904C11.8446 5.00788 11.8702 5.32343 11.6914 5.53383L7.44139 10.5338C7.25974 10.7475 6.93787 10.77 6.72825 10.5837L4.47825 8.5837C4.27186 8.40024 4.25327 8.0842 4.43673 7.87781C4.62019 7.67142 4.93622 7.65283 5.14261 7.83629L7.01053 9.49669L10.9295 4.88618Z"
                fill="currentColor"
              />
            </svg>

            <span className="text-xl text-sky-50">из р-на Дагомыс</span>
          </li>
        </ul>
      </div>
      <button
        onClick={() => setShow(true)}
        className="mx-auto mt-5 flex  bg-sky-400 transition-all duration-200 ease-in-out hover:bg-sky-50 hover:text-blue-900"
      >
        Получить предложение
      </button>
      <div className="z-50">
        <Modal onClose={() => setShow(false)} show={show} />
      </div>
    </div>
  );
};

export default SpecialOffer;
