import React, { useState } from "react";
import Modal from "../Modal";
import { motion } from "framer-motion";

const SpecialOffer2 = () => {
  const [show, setShow] = useState(false);

  return (
    <div className="mx-3 max-w-7xl rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 px-8 py-3 lg:mx-auto">
      <div className="flex flex-wrap items-center justify-between gap-5">
        <div className="flex flex-col gap-3">
          <motion.h2 className="text-xl font-bold text-white lg:text-3xl">
            Закажите индивидуальное коммерческое предложение !
          </motion.h2>
          <p className="text-xl text-sky-50 lg:text-2xl">
            Курьерская доставка:{" "}
            <span className="font-bold">от 350 р - точка</span>
          </p>
        </div>

        <div className="py-3 lg:py-6">
          <button
            onClick={() => setShow(true)}
            className="mx-auto flex cursor-pointer bg-sky-400 py-2 font-semibold uppercase transition-all duration-200 ease-in-out hover:bg-sky-50 hover:text-blue-900 lg:py-5"
          >
            Воспользоваться предложением
          </button>
        </div>
      </div>
      <div className="">
        <Modal onClose={() => setShow(false)} show={show} />
      </div>
    </div>
  );
};

export default SpecialOffer2;
