import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import logoWhite from "../assets/img/logo_ref193.png";
import menuItem from "../data/db.json";

const Footer = () => {
  return (
    <div className="w-full bg-blue-900 text-center">
      <div className="z-50 mx-auto max-w-[1440px] gap-8 rounded-t-xl bg-blue-900 py-3 px-4 text-gray-300">
        <div className="flex flex-wrap items-center justify-start gap-2 lg:gap-10">
          <NavLink to="/">
            <div className="">
              <img src={logoWhite} alt="" className="w-[200px]" />
            </div>
          </NavLink>
          <div className="">
            <p className="py-2 text-start text-sm text-sky-50">
              Первая курьерская доставка <br /> продуктов в температурном режиме{" "}
              <br />в Сочи от -18° до +12°
            </p>
          </div>
          <div className="flex flex-row flex-wrap items-center justify-center gap-4 rounded-md border p-4 underline-offset-4">
            {menuItem.menuItems.map((item) => (
              <ul key={item.id} className="">
                <Link to={item.id} spy={true} smooth="true">
                  <li className="text-sm text-sky-50 hover:underline">
                    {item.title}
                  </li>
                </Link>
              </ul>
            ))}
          </div>
          <div className="flex flex-row gap-1 text-xs">
            <NavLink to="/privacy">
              <span className="cursor-pointer underline-offset-2 hover:underline">
                Политика конфиденциальности
              </span>
            </NavLink>
          </div>
        </div>
      </div>
      <span className="text-center text-xs text-gray-300">
        © 2023 | Все права защищены | ИП Кривошеев А.Г. ОГРН 304667336600621 ИНН
        666300013704
      </span>
    </div>
  );
};

export default Footer;
