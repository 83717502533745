import React, { useState } from "react";
import { BsWhatsapp } from "react-icons/bs";
import { AiOutlineSend, AiOutlineCloseCircle } from "react-icons/ai";
import logo from "../assets/img/logo_circle_white.png";
import { AnimatePresence, motion } from "framer-motion";

const WhatsApp = (props) => {
  const [input, setInput] = useState("");
  const [show, setShow] = useState(false);

  return (
    <AnimatePresence>
      <div className="">
        <div
          onClick={() => setShow(!show)}
          className="relative flex h-[50px] w-[50px] cursor-pointer items-center justify-center rounded-full bg-[#128c7e] text-white"
        >
          <BsWhatsapp size={20} />
          <span className="absolute -z-10 flex h-[60px] w-[60px] animate-ping items-center justify-center rounded-full bg-[#128c7e] text-white"></span>
        </div>

        {show ? (
          <motion.div
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 100, opacity: 0 }}
            onClick={(e) => e.stopPropagation}
            className="fixed bottom-[100px] left-8 h-[230px] max-w-[300px] rounded-md bg-bgWhatsApp "
          >
            <div
              onClick={() => setShow(false)}
              className="absolute top-2 right-2 cursor-pointer hover:text-black "
            >
              <AiOutlineCloseCircle
                size={30}
                className="text-white transition-all duration-150 ease-in-out hover:scale-110"
              />
            </div>
            <div className="flex w-full rounded-t-md bg-[#128c7e] p-3 text-white">
              <img src={logo} alt="" width={20} />
              <p className="ml-2 font-roboto text-sm">РЕФ193 Чат</p>
            </div>
            <div className="flex flex-col items-stretch justify-center p-2">
              <p className="rounded-md bg-[#fffdfe] p-2 text-sm text-black">
                Доброго времени суток! Спасибо за обращение в нашу компанию!
                <br />
                Напишите ваш вопрос, отправьте и мы ответим Вам в самое
                ближайшее время!
              </p>
              <div className="flex w-full flex-row items-center justify-between gap-2 p-3">
                <input
                  onChange={(e) => setInput(e.target.value)}
                  type="text"
                  placeholder="Введите сообщение"
                  value={input}
                  id=""
                  className=" w-full rounded-md px-3 py-2 outline-none"
                />
                <p
                  type="submit"
                  className="m-auto rounded-full bg-[#128c7e] p-2"
                >
                  <a
                    href={`https://wa.me/79126220099?text=${input}`}
                    className=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AiOutlineSend size={20} className="text-white" />
                  </a>
                </p>
              </div>
            </div>
          </motion.div>
        ) : (
          <></>
        )}
      </div>
    </AnimatePresence>
  );
};

export default WhatsApp;
