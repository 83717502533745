import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { BsArrowUpCircle } from "react-icons/bs";

import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import About from "../components/Hero/About";
import Services from "../components/Hero/Services";
import Autopark from "../components/Hero/Autopark";
import Price from "../components/Hero/Price";
import Testimonials from "../components/Testimonials";
import Contacts from "../components/Hero/Contacts";

import Delivery from "../components/Hero/Delivery";
import SpecialOffer from "../components/Hero/SpecialOfferComponent";
import SpecialOffer2 from "../components/Hero/SpecialOffer2";
import WhatsApp from "../components/WhatsApp";

const HomePage = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div className="z-0 mx-auto w-full bg-gradient-to-b from-blue-900 via-sky-400 to-sky-50 ">
        <Navbar />
        <Hero />
      </div>

      <div className="bg-sky-50">
        <SpecialOffer2 />
      </div>

      <div
        id="1"
        className="w-full bg-sky-50 bg-[url('../src/assets/img/logo_circle_white.png')] bg-contain bg-right-bottom bg-no-repeat pb-6 pt-10"
      >
        <About />
      </div>

      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        id="2"
        className="bg-sky-50"
      >
        <Testimonials />
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        id="3"
        className="opacity-1 w-full bg-sky-400 bg-gradient-to-b from-sky-50 via-sky-400 to-sky-400"
      >
        <Services />
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        id="4"
        className="mix-blend-dark w-full bg-gradient-to-b from-sky-400 via-blue-400 to-sky-50 bg-cover bg-left  bg-no-repeat"
      >
        <Autopark />
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        id="5"
        className="w-full bg-sky-50 bg-bgMap bg-center bg-no-repeat"
      >
        <Delivery page="4" />
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        id="6"
        className="w-full bg-gradient-to-b from-sky-50 via-sky-400 to-sky-400"
      >
        <Price />
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        className="w-full bg-blue-900 shadow-2xl"
      >
        <SpecialOffer />
      </motion.div>

      <div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        id="7"
        className="bg-sky-50 shadow-3xl"
      >
        <Contacts />
      </div>

      {showButton && (
        <div
          onClick={scrollToTop}
          className="fixed bottom-8 right-5 z-50 flex h-[50px] w-[50px] cursor-pointer items-center justify-center rounded-full bg-blue-900/80 text-lg text-sky-50 transition-colors duration-150 ease-in-out hover:bg-sky-400 hover:text-blue-900"
        >
          <BsArrowUpCircle size={20} />
        </div>
      )}

      <div className="fixed bottom-8 left-8">
        <WhatsApp onClose={() => setShowButton(false)} />
      </div>
    </div>
  );
};

export default HomePage;
