import React from "react";

const Privacy = () => {
  return (
    <section className="bg-sky-50">
      <div className="container mx-auto w-full px-4">
        <div className="my-3 mx-auto max-w-[1400px] p-10">
          <div className="">
            <h2 className="mt-6 text-2xl font-bold">
              Политика в отношении обработки персональных данных{" "}
            </h2>
            <h3 class="mt-5 text-xl font-semibold">1. Общие положения</h3>
            <p class="mt-4">
              Настоящая политика обработки персональных данных составлена в
              соответствии с требованиями Федерального закона от 27.07.2006.
              №152-ФЗ «О персональных данных» и определяет порядок обработки
              персональных данных и меры по обеспечению безопасности
              персональных данных ООО «РЕФ193.РФ» (далее – Оператор).
            </p>{" "}
            <ol class="list-outside list-decimal">
              <li class="mt-2">
                Оператор ставит своей важнейшей целью и условием осуществления
                своей деятельности соблюдение прав и свобод человека и
                гражданина при обработке его персональных данных, в том числе
                защиты прав на неприкосновенность частной жизни, личную и
                семейную тайну.
              </li>
              <li class="mt-2">
                Настоящая политика Оператора в отношении обработки персональных
                данных (далее – Политика) применяется ко всей информации,
                которую Оператор может получить о посетителях веб-сайта{" "}
                <a class="privacy__link" href="https://ref193.ru">
                  https://ref193.ru
                </a>
                .
              </li>
            </ol>{" "}
            <h3 class="mt-5 text-xl font-semibold">
              2. Основные понятия, используемые в Политике
            </h3>
            <ol class="list-outside list-decimal">
              <li class="mt-2">
                Автоматизированная обработка персональных данных &ndash;
                обработка персональных данных с помощью средств вычислительной
                техники;
              </li>
              <li class="mt-2">
                Блокирование персональных данных &ndash; временное прекращение
                обработки персональных данных (за исключением случаев, если
                обработка необходима для уточнения персональных данных);
              </li>
              <li class="mt-2">
                Веб-сайт &ndash; совокупность графических и информационных
                материалов, а также программ для ЭВМ и баз данных,
                обеспечивающих их доступность в сети интернет по сетевому адресу{" "}
                <a class="privacy__link" href="https://ref193.ru">
                  https://ref193.ru
                </a>
                ;
              </li>
              <li class="mt-2">
                Информационная система персональных данных &mdash; совокупность
                содержащихся в базах данных персональных данных, и
                обеспечивающих их обработку информационных технологий и
                технических средств;
              </li>
              <li class="mt-2">
                Обезличивание персональных данных &mdash; действия, в результате
                которых невозможно определить без использования дополнительной
                информации принадлежность персональных данных конкретному
                Пользователю или иному субъекту персональных данных;
              </li>
              <li class="mt-2">
                Обработка персональных данных &ndash; любое действие (операция)
                или совокупность действий (операций), совершаемых с
                использованием средств автоматизации или без использования таких
                средств с персональными данными, включая сбор, запись,
                систематизацию, накопление, хранение, уточнение (обновление,
                изменение), извлечение, использование, передачу
                (распространение, предоставление, доступ), обезличивание,
                блокирование, удаление, уничтожение персональных данных;
              </li>
              <li class="mt-2">
                Оператор &ndash; государственный орган, муниципальный орган,
                юридическое или физическое лицо, самостоятельно или совместно с
                другими лицами организующие и (или) осуществляющие обработку
                персональных данных, а также определяющие цели обработки
                персональных данных, состав персональных данных, подлежащих
                обработке, действия (операции), совершаемые с персональными
                данными;
              </li>
              <li class="mt-2">
                Персональные данные &ndash; любая информация, относящаяся прямо
                или косвенно к определенному или определяемому Пользователю
                веб-сайта{" "}
                <a class="privacy__link" href="https://ref193.ru">
                  https://ref193.ru
                </a>
                ;
              </li>
              <li class="mt-2">
                Пользователь &ndash; любой посетитель веб-сайта{" "}
                <a class="privacy__link" href="https://ref193.ru">
                  https://ref193.ru
                </a>
                ;
              </li>
              <li class="mt-2">
                Предоставление персональных данных &ndash; действия,
                направленные на раскрытие персональных данных определенному лицу
                или определенному кругу лиц;
              </li>
              <li class="mt-2">
                Распространение персональных данных &ndash; любые действия,
                направленные на раскрытие персональных данных неопределенному
                кругу лиц (передача персональных данных) или на ознакомление с
                персональными данными неограниченного круга лиц, в том числе
                обнародование персональных данных в средствах массовой
                информации, размещение в информационно-телекоммуникационных
                сетях или предоставление доступа к персональным данным
                каким-либо иным способом;
              </li>
              <li class="mt-2">
                Трансграничная передача персональных данных &ndash; передача
                персональных данных на территорию иностранного государства
                органу власти иностранного государства, иностранному физическому
                или иностранному юридическому лицу;
              </li>
              <li class="mt-2">
                Уничтожение персональных данных &ndash; любые действия, в
                результате которых персональные данные уничтожаются безвозвратно
                с невозможностью дальнейшего восстановления содержания
                персональных данных в информационной системе персональных данных
                и (или) результате которых уничтожаются материальные носители
                персональных данных.
              </li>
            </ol>
            <h3 class="mt-5 text-xl font-semibold">
              3. Оператор может обрабатывать следующие персональные данные
              Пользователя
            </h3>
            <ol>
              <li class="mt-2">Фамилия, имя, отчество;</li>
              <li class="mt-2">Электронный адрес;</li>
              <li class="mt-2">Номера телефонов;</li>
              <li class="mt-2">
                Также на сайте происходит сбор и обработка обезличенных данных о
                посетителях (в т.ч. файлов &laquo;cookie&raquo;) с помощью
                сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика и
                других).
              </li>
              <li class="mt-2">
                Вышеперечисленные данные далее по тексту Политики объединены
                общим понятием Персональные данные.
              </li>
            </ol>
            <h3 class="mt-5 text-xl font-semibold">
              4. Цели обработки персональных данных
            </h3>
            <ol class="list-outside list-decimal">
              <li class="mt-2">
                Цель обработки персональных данных Пользователя &mdash;
                информирование Пользователя посредством отправки электронных
                писем; для составления коммерческого предложения.
              </li>
              <li class="mt-2">
                Также Оператор имеет право направлять Пользователю уведомления о
                новых продуктах и услугах, специальных предложениях и различных
                событиях. Пользователь всегда может отказаться от получения
                информационных сообщений, направив Оператору письмо на адрес
                электронной почты{" "}
                <a class="privacy__link" href="mailto:ref193@yandex.ru">
                  ref193@yandex.ru
                </a>{" "}
                с пометкой &laquo;Отказ от уведомлениях о новых продуктах и
                услугах и специальных предложениях&raquo;.
              </li>
              <li class="mt-2">
                ООбезличенные данные Пользователей, собираемые с помощью
                сервисов интернет-статистики, служат для сбора информации о
                действиях Пользователей на сайте, улучшения качества сайта и его
                содержания.
              </li>
            </ol>
            <h3 class="mt-5 text-xl font-semibold">
              5. Правовые основания обработки персональных данных
            </h3>
            <ol class="list-outside list-decimal">
              <li class="mt-2">
                Оператор обрабатывает персональные данные Пользователя только в
                случае их заполнения и/или отправки Пользователем самостоятельно
                через специальные формы, расположенные на сайте{" "}
                <a class="privacy__link" href="https://ref193.ru">
                  https://ref193.ru
                </a>
                . Заполняя соответствующие формы и/или отправляя свои
                персональные данные Оператору, Пользователь выражает свое
                согласие с данной Политикой.
              </li>
              <li class="mt-2">
                Оператор обрабатывает обезличенные данные о Пользователе в
                случае, если это разрешено в настройках браузера Пользователя
                (включено сохранение файлов &laquo;cookie&raquo; и использование
                технологии JavaScript).
              </li>
            </ol>
            <h3 class="mt-5 text-xl font-semibold">
              6. Порядок сбора, хранения, передачи и других видов обработки
              персональных данных
            </h3>
            <p class="privacy__text">
              Безопасность персональных данных, которые обрабатываются
              Оператором, обеспечивается путем реализации правовых,
              организационных и технических мер, необходимых для выполнения в
              полном объеме требований действующего законодательства в области
              защиты персональных данных.
            </p>
            <ol class="list-outside list-decimal">
              <li class="mt-2">
                Оператор обеспечивает сохранность персональных данных и
                принимает все возможные меры, исключающие доступ к персональным
                данным неуполномоченных лиц.
              </li>
              <li class="mt-2">
                Персональные данные Пользователя никогда, ни при каких условиях
                не будут переданы третьим лицам, за исключением случаев,
                связанных с исполнением действующего законодательства.
              </li>
              <li class="mt-2">
                В случае выявления неточностей в персональных данных,
                Пользователь может актуализировать их самостоятельно, путем
                направления Оператору уведомление на адрес электронной почты
                Оператора{" "}
                <a class="privacy__link" href="mailto:ref193@yandex.ru">
                  ref193@yandex.ru
                </a>{" "}
                с пометкой &laquo;Актуализация персональных данных&raquo;.
              </li>
              <li class="mt-2">
                Срок обработки персональных данных является неограниченным.
                Пользователь может в любой момент отозвать свое согласие на
                обработку персональных данных, направив Оператору уведомление
                посредством электронной почты на электронный адрес Оператора{" "}
                <a class="privacy__link" href="mailto:ref193@yandex.ru">
                  ref193@yandex.ru
                </a>{" "}
                с пометкой &laquo;Отзыв согласия на обработку персональных
                данных&raquo;.
              </li>
            </ol>
            <h3 class="mt-5 text-xl font-semibold">
              7. Трансграничная передача персональных данных
            </h3>
            <ol class="list-outside list-decimal">
              <li class="mt-2">
                Оператор до начала осуществления трансграничной передачи
                персональных данных обязан убедиться в том, что иностранным
                государством, на территорию которого предполагается осуществлять
                передачу персональных данных, обеспечивается надежная защита
                прав субъектов персональных данных.
              </li>
              <li class="mt-2">
                Трансграничная передача персональных данных на территории
                иностранных государств, не отвечающих вышеуказанным требованиям,
                может осуществляться только в случае наличия согласия в
                письменной форме субъекта персональных данных на трансграничную
                передачу его персональных данных и/или исполнения договора,
                стороной которого является субъект персональных данных.
              </li>
            </ol>
            <h3 class="mt-5 text-xl font-semibold">
              8. Заключительные положения
            </h3>
            <ol class="list-outside list-decimal">
              <li class="mt-2">
                Пользователь может получить любые разъяснения по интересующим
                вопросам, касающимся обработки его персональных данных,
                обратившись к Оператору с помощью электронной почты
                <a class="privacy__link" href="mailto:ref193@yandex.ru">
                  ref193@yandex.ru
                </a>
                .
              </li>
              <li class="mt-2">
                В данном документе будут отражены любые изменения политики
                обработки персональных данных Оператором. Политика действует
                бессрочно до замены ее новой версией.
              </li>
              <li class="mt-2">
                Актуальная версия Политики в свободном доступе расположена в
                сети Интернет по адресу http://www.ref193.ru/privacy
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Privacy;
