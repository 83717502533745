import React from "react";

const services = [
  {
    id: 1,
    name: "Доставка продуктов питания для поставщиков в HoReCa:",
    desc: "Кафе, ресторанам, барам, санаториям, гостиницам, гостевым домам, кейтеринг, различные мероприятия",
    img: "./images/services/horeca.jpeg",
  },
  {
    id: 2,
    name: "Доставка продуктов питания в торговые сети и минимаркеты",
    desc: "Перевозка замороженной и охлажденной продукции, продуктов питания, хлебо-булочных, кондитерских изделий, лекарственных средств, цветов и других товаров, требующих особых условий транспортировки",
    img: "./images/services//markets.jpeg",
  },
  {
    id: 3,
    name: "Ответственное хранение продукции, требующей температурного режима",
    desc: "Поможем разместить на температурном складе вашу продукцию, а при поступлении заявки скомплектуем и доставим вашим клиентам",
    img: "./images/services/warehouse.jpeg",
  },
  {
    id: 4,
    name: "Логистические услуги для больших санаториев",
    desc: "Внутренние перемещения продуктов и грузов по территориям больших санаториев",
    img: "./images/services/c2c.webp",
  },
  {
    id: 5,
    name: "Логистические услуги для интернет-магазинов",
    desc: "Доставим продукцию в рефрижераторе в руки вашему клиенту",
    img: "./images/services/e-commerce.jpeg",
  },
  {
    id: 6,
    name: "Доставка продуктов питания для поставщиков в дарксторы",
    desc: "Доставим ваш товар, требующий соблюдения температурного режима при перевозке на склад ОЗОНа в Сочи",
    img: "./images/services/darkstore.jpg",
  },
  {
    id: 7,
    name: "Курьерская доставка для компаний любого формата",
    desc: "Для производителей-продавцов своей продукции, требующей соблюдения температурного режима при перевозке",
    img: "./images/services/courier.jpeg",
  },
  {
    id: 8,
    name: "Доставка корпоративных подарков (в т.ч. без температурного режима)",
    desc: "Доставка открыток, пригласительных, подарков (конфеты, спиртные напитки весом от 1 кг. до 5 кг.), нестандартных отправлений (подарочные корзины)",
    img: "./images/services/gifts.jpeg",
  },
];

const Services = () => {
  return (
    <div className="container relative mx-auto px-4 sm:px-6 lg:px-8 lg:py-4">
      <h3 className="p-5 text-center font-roboto text-3xl font-thin text-blue-900/80 ">
        Услуги которые мы осуществляем:
      </h3>
      <div className="grid gap-6 sm:grid-cols-2 md:grid-cols-2 md:gap-8 lg:grid-cols-3 xl:grid-cols-4">
        {services.map((item) => (
          <div key={item.id} className="">
            <div className="rounded-[6px] bg-white p-6 ">
              <div className="space-y-4 ">
                <div className="flex h-[150px] min-h-[150px] max-w-[300px] items-center justify-center overflow-hidden transition-all duration-150 ease-in-out hover:scale-95 hover:rounded-lg">
                  <img
                    src={item.img}
                    alt=""
                    className="h-full w-full object-cover grayscale-[50%] hover:grayscale-0"
                  />
                </div>

                <div className="min-h-[70px]">
                  <p className="text-md md:text-md font-semibold text-blue-900">
                    {item.name}
                  </p>
                </div>
                <hr />
                <div className="min-h-[150px]">
                  <p className="md:text-md text-sm text-slate-500">
                    {item.desc}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
