import React, { useState } from "react";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { BsWhatsapp } from "react-icons/bs";

import { useForm } from "react-hook-form";
import { init, sendForm } from "emailjs-com";
init("nqYDw2PzTyIlWqbwY");

// const routing = [
//   {
//     value: "center-sochi",
//     label: "Центральный Сочи",
//   },
//   {
//     value: "sochi-dagomys",
//     label: "Сочи - Дагомыс",
//   },
//   {
//     value: "sochi-adler",
//     label: "Сочи - Адлер",
//   },
//   {
//     value: "sochi-aeroport",
//     label: "Сочи - Аэропорт",
//   },
//   {
//     value: "sochi-olimpic",
//     label: "Сочи - Олимпийский парк",
//   },
//   {
//     value: "adler-polyana",
//     label: "Адлер - Красная Поляна",
//   },
//   {
//     value: "sochi-polyana",
//     label: "Сочи - Красная Поляна",
//   },
//   {
//     value: "sochi-lazarevskoe",
//     label: "Сочи - Лазаревское",
//   },
//   {
//     value: "sochi-lazarevskoe",
//     label: "Адлер - Дагомыс",
//   },
//   {
//     value: "intercity",
//     label: "Междугородние перевозки",
//   },
// ];

const Contacts = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onErrors = (errors) => console.error(errors);
  const [statusMessage, setStatusMessage] = useState("");

  const [contactNumber, setContactNumber] = useState("");
  const generateContactNumber = () => {
    const numStr = "" + ((Math.random() * 1000000) | 0);
    setContactNumber(numStr.substring(numStr.length - 6));
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    generateContactNumber();
    sendForm("service_j8nc61c", "ref193_feedback", "#contact-form").then(
      function (response) {
        setStatusMessage("Сообщение отправлено!");
        statusMessage.className = "status-message success";
        setTimeout(() => {
          statusMessage.className = "status-message";
        }, 5000);
      },
      function (error) {
        setStatusMessage("Не удалось отправить сообщение! Повторите");
        statusMessage.className = "status-message failure";
        setTimeout(() => {
          statusMessage.className = "status-message";
        }, 5000);
      }
    );
    reset();
  };

  return (
    <div className="mx-auto max-w-[1000px] px-4 py-10 sm:px-6 lg:px-8 lg:py-14">
      <div className="mb-7">
        <h1 className="text-center font-poppins text-4xl font-light tracking-tight text-gray-800  sm:text-5xl">
          Будем на связи
        </h1>
        <p className="text-normal text-md mt-2 text-center font-poppins font-extralight text-gray-600">
          Заполните форму, специалист свяжется с вами, чтобы уточнить детали и
          составить предложение
        </p>
      </div>
      <div className="flex flex-row flex-wrap lg:flex-nowrap">
        {/* contact part */}
        <div className="mr-2 w-full rounded-md p-6 dark:bg-gray-800 sm:rounded-lg lg:basis-1/3">
          {/* <GrContact size={150} className="absolute opacity-[5%]" /> */}
          <p className="font-poppins font-light">Контактная информация:</p>

          <p className="font-poppins text-lg font-light tracking-wide text-gray-600">
            Александр Кривошеев
          </p>

          <p className="mt-8 text-sm text-slate-500">Электронная почта</p>
          <a
            href="mailto:ref193@yandex.ru"
            className="whitespace-nowrap text-sky-50 transition-colors duration-200 hover:text-blue-900"
          >
            <div className="mt-1 flex cursor-pointer items-center text-gray-600 hover:text-sky-400">
              <MdOutlineAlternateEmail size={25} />
              <p className="ml-2 font-poppins text-xl font-normal tracking-wide">
                ref193@yandex.ru
              </p>
            </div>
          </a>
          <p className="mt-8 text-sm text-slate-500">
            Связь по телефону или whatsapp
          </p>
          <a
            href="https://wa.me/79667750099"
            className="flex flex-row"
            target="_blank"
            rel="noreferrer"
          >
            <div className="mt-1 flex cursor-pointer items-center text-gray-600 hover:text-[#25d366]">
              <BsWhatsapp size={20} />
              <p className="ml-2 font-poppins text-xl font-normal tracking-wide">
                +7 966 77 500 99
              </p>
            </div>
          </a>

          <p className="mt-8 text-sm text-slate-500">Реквизиты компании:</p>
          <p className="font-poppins text-sm font-normal tracking-wide text-gray-500">
            ИП Кривошеев А.Г.
          </p>
          <p className="font-poppins text-sm font-normal tracking-wide text-gray-500">
            354000 г. Сочи, переулок Дагомысский 9-17
          </p>

          <p className=" font-poppins text-sm font-normal tracking-wide text-gray-500">
            ИНН: 666300013704
          </p>
          <p className="font-poppins text-sm font-normal tracking-wide text-gray-500">
            ОГРН: 304667336600621
          </p>
        </div>

        {/* form  part */}
        <div className="w-full px-4 lg:basis-2/3">
          <form
            id="contact-form"
            onSubmit={handleSubmit(onSubmit, onErrors)}
            className="flex flex-col flex-wrap justify-center gap-3 p-3 lg:gap-6"
          >
            <div className="flex flex-row flex-wrap justify-between gap-3">
              <div className="flex w-full flex-col">
                <input
                  {...register("userName", {
                    required: "Поле обязательно для заполнения",
                    maxLength: 20,
                    minLength: {
                      value: 4,
                      message: "Введите минимум 4 символа",
                    },
                  })}
                  type="text"
                  id="name"
                  placeholder="Введите имя"
                  className="w-100 rounded-lg  bg-white py-3 px-3 font-medium text-gray-800 placeholder:font-extralight focus:border-blue-900 focus:outline-none  "
                />
                <div className="text-sm text-red-500">
                  {errors?.userName && (
                    <p>
                      {errors?.userName.message || "Заполните правильно поле !"}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex w-full flex-col">
                <input
                  {...register("userEmail", {
                    required: true,
                    pattern: /\S+@\S+\.\S+/,
                    message:
                      "Введенные данные не подходит под формат электронной почты",
                  })}
                  type="email"
                  id="email"
                  placeholder="Введите адрес электронной почты"
                  className="w-100 rounded-lg bg-white py-3 px-3 font-medium text-gray-800 placeholder:font-extralight focus:border-blue-900 focus:outline-none "
                />
                <div className="text-sm text-red-500">
                  {errors?.userEmail && (
                    <p>
                      {errors?.userEmail.message || "Заполните адрес почты !"}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-row flex-wrap justify-between gap-3">
              {/* <div className="flex w-full flex-col">
                  <Controller
                    {...register("userRouting")}
                    control={control}
                    name="routing"
                    rules={{
                      required: "Выберите маршрут",
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <div>
                        <ReactSelect
                          placeholder="Выбрать маршрут"
                          options={routing}
                          value={getValue(value)}
                          onChange={(newValue) => onChange(newValue)}
                        />
                        {error && (
                          <div style={{ color: "red" }}>{error.message}</div>
                        )}
                      </div>
                    )}
                  />
                </div> */}

              <div className="flex w-full flex-col">
                <input
                  {...register("userAddition")}
                  type="text"
                  id="addition"
                  placeholder="Тема сообщения"
                  className="w-100 rounded-lg bg-white py-3 px-3 font-medium text-gray-800 placeholder:font-extralight focus:border-blue-900 focus:outline-none "
                />
              </div>
            </div>
            <div className="w-full">
              <textarea
                {...register("userMessage", {
                  required: true,
                })}
                id=""
                maxLength="1000"
                cols="30"
                rows="10"
                className="w-full rounded-lg bg-white py-3 px-3 font-medium text-gray-800 placeholder:font-extralight focus:border-blue-900 focus:outline-none"
                placeholder="Введите ваше сообщение"
              ></textarea>
            </div>

            <input type="hidden" name="contact_number" value={contactNumber} />

            <input
              type="submit"
              className="relative w-full cursor-pointer rounded-md bg-blue-900 px-6 py-3 text-sm text-white hover:bg-sky-400 group-hover:text-white"
              value="Отправить сообщение"
            />

            <p className="text-right text-xs text-gray-400">
              * Отправляя данные, Вы соглашаетесь с Правилами обработки
              персональных данных
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
