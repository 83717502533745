import React from "react";

import car_photo1 from "../assets/img/autopark_1.jpg";
import car_photo2 from "../assets/img/autopark_2.jpg";
import car_photo3 from "../assets/img/autopark_5.jpg";
import car_photo4 from "../assets/img/auto_map.webp";

import m2 from "../assets/icons/m2.png";
import heightFloor from "../assets/icons/height1.png";
import heightSetting from "../assets/icons/height2.png";
import height3 from "../assets/icons/height3.png";
import width1 from "../assets/icons/width1.png";
import width2 from "../assets/icons/width2.png";

const text = [
  {
    id: 1,
    title: "Максимальный объем",
    description: "До 2 куб.м",
  },
  {
    id: 2,
    title: "Грузоподъемность",
    description: "до 700 кг",
  },
  {
    id: 3,
    title: "Холодильная установка",
    description: "Zanotti",
  },
  {
    id: 4,
    title: "Капсула",
    description: "Krossi",
  },
  {
    id: 5,
    title: "Температурный режим",
    description: "-18 C° до +12 C°",
  },
  {
    id: 6,
    title: "Загрузки",
    description: "боковая и задняя",
  },
];

const measure = [
  {
    id: 1,
    title: "Длина по полу",
    dimension: "[ 165 см ]",
    icon: m2,
  },
  {
    id: 2,
    title: "Высота до потолка",
    dimension: "[ 104 см ]",
    icon: heightFloor,
  },
  {
    id: 3,
    title: "Высота до установки",
    dimension: "[ 86 см ]",
    icon: heightSetting,
  },
  {
    id: 4,
    title: "Ширина по полу от стенки до стенки",
    dimension: "[ 122 см ]",
    icon: width1,
  },
  {
    id: 5,
    title: "Ширина проема",
    dimension: "[ 109 см ]",
    icon: height3,
  },
  {
    id: 6,
    title: "Высота проема",
    dimension: "[ 98 см ]",
    icon: width2,
  },
];

const Auto = () => {
  return (
    <div className="container w-full overflow-hidden rounded-lg bg-sky-50 shadow-xl">
      <h2 className="pt-3 text-center text-xl font-light text-blue-900 md:pt-4 lg:text-3xl">
        Peugeot Partner Long
      </h2>

      <div className="flex flex-col gap-2 p-5 md:hidden">
        {text.map((item) => (
          <div
            key={item.id}
            className="grid grid-cols-2 items-center gap-3 border text-blue-900"
          >
            <span className="border-r p-1 text-sm">{item.title} :</span>
            <span className="text-sm font-semibold">{item.description}</span>
          </div>
        ))}
      </div>

      <div className="hidden grid-cols-1 gap-2 px-6 xs:grid-cols-2 md:grid md:grid-cols-3 lg:grid-cols-4 lg:gap-4 xl:grid-cols-6">
        {text.map((item) => (
          <div
            key={item.id}
            className="static m-4 min-h-[150px] w-[180px] rounded-md bg-sky-400 "
          >
            <div className="absolute -m-1 min-h-[150px] w-[180px] rounded-md bg-sky-50 shadow-lg transition-all duration-150 ease-out hover:m-0 hover:shadow-xl hover:ease-in ">
              <div className="mih-h-40 lg:min-h-[60px]">
                <p className="text-md m-4 break-words text-center font-medium text-blue-900 lg:text-lg">
                  {item.title}
                </p>
              </div>

              <hr className="border-t-1 m-4 rounded-2xl" />
              <p className="m-4 text-center text-lg">{item.description}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3">
        <div className="relative col-span-2 w-full justify-center px-4">
          <div className="w-full">
            <img
              src={car_photo4}
              alt="Peugeot Partner Long with Zanotti"
              className="saturate-80 h-full w-full "
            />
          </div>
        </div>

        {/* Габариты капсулы */}
        <div className="w-full">
          <h3 className="block p-4 text-center text-lg font-light text-blue-900 sm:text-3xl md:text-2xl lg:text-2xl">
            [ Габариты капсулы: ]
          </h3>

          <div className="flex flex-col gap-2 ">
            {measure.map((item) => (
              <div
                key={item.id}
                className="flex flex-row flex-wrap items-center justify-start gap-2 border bg-white p-3 transition-all duration-150 ease-in-out hover:scale-105 md:justify-center lg:justify-start"
              >
                <div className="max-h-[40px] max-w-[40px] p-1">
                  <img
                    src={item.icon}
                    alt={item.title}
                    className="h-full w-full"
                  />
                </div>
                <div className="">
                  <h5 className="md:text-md lg:text-md text-start text-sm 2xl:text-lg">
                    {item.title}
                  </h5>
                </div>
                <div className="">
                  <span className="md:text-md text-sm font-semibold lg:text-lg">
                    {item.dimension}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* фото автомобиля */}
      <div className="grid grid-cols-1 items-center justify-center gap-5 p-4 lg:grid-cols-3">
        <div className="w-[100%] overflow-hidden">
          <img
            src={car_photo1}
            alt="Peugeot Partner Long with Zanotti"
            className="w-full shadow-lg saturate-50 transition-transform duration-200 hover:scale-105"
          />
        </div>

        <div className="w-[100%] overflow-hidden">
          <img
            src={car_photo2}
            alt="Peugeot Partner Long with Zanotti"
            className="w-[100%] shadow-lg saturate-50 transition-transform duration-200 hover:scale-105"
          />
        </div>

        <div className="w-[100%] overflow-hidden">
          <img
            src={car_photo3}
            alt="Peugeot Partner Long with Zanotti"
            className="w-[100%] shadow-lg saturate-50 transition-transform duration-200 hover:scale-105"
          />
        </div>
      </div>
    </div>
  );
};

export default Auto;
