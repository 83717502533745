import car from "../assets/img/car_main_page2.png";
import medicalBook from "../assets/img/medical_book1.png";
import sanitaryStamp from "../assets/img/sanitary_stamp.png";
import { motion } from "framer-motion";

const Hero = () => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 1 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="relative w-full px-10 ">
        <div className="container relative mx-auto flex max-w-[1400px] flex-row items-center justify-center px-4 sm:px-6 ">
          <div className="col-span-2 grid items-center justify-center md:grid-cols-1 lg:grid-cols-2">
            {/* title */}
            <motion.div initial={{ x: 100 }} animate={{ x: 0 }}>
              <h1 className="text-2xl leading-snug text-sky-50 lg:text-5xl">
                Первая курьерская доставка продуктов в температурном режиме
              </h1>
              <h1 className="mt-1 text-3xl text-blue-900 lg:mt-2 lg:text-5xl">
                в Сочи
              </h1>
              {/* <h1 className="text-xl text-sky-50/50 lg:text-2xl">а также</h1> */}
              {/* <Typed
                className="w-auto whitespace-nowrap py-3 text-2xl font-extrabold text-blue-900 md:w-2/4 md:text-3xl lg:w-full lg:text-4xl xl:w-2/3"
                strings={["в Краснодар", "в Ростов-На-Дону", "в Крым"]}
                typeSpeed={100}
                backSpeed={30}
                loop
              /> */}
              <div className="">
                <h2 className="w-auto whitespace-nowrap py-3 text-3xl font-extrabold text-sky-50 md:w-2/4 md:text-3xl lg:w-full lg:text-5xl xl:w-2/3">
                  от -18&#176; до +12&#176;
                </h2>
              </div>
            </motion.div>

            {/* main image */}
            <div className="relative flex flex-col items-center justify-center">
              <div className="max-w-[700px]">
                <img src={car} alt="РЕФ193.рф" className="h-full w-full" />
              </div>

              <div className="absolute top-0 left-[0px] -z-10 max-w-[80px] sm:max-w-[150px] md:-rotate-12 lg:top-[-10px] lg:left-[50px] xl:top-[60px] xl:left-[50px]">
                <img src={medicalBook} alt="" className="h-full w-full" />
              </div>
              <div className="absolute right-[-30px] top-[-20px] max-w-[150px] p-3 sm:max-w-[230px] md:right-[20px] md:rotate-12 lg:top-[-20px] lg:right-[-10px] xl:top-[30px] xl:right-[0px]">
                <img src={sanitaryStamp} alt="" className="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Hero;
