//React imports
import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";

//Layout imports
import RootLayout from "./layouts/RootLayout";

//Pages imports
import HomePage from "./pages/HomePage";
import ErrorPage from "./pages/ErrorPage";
import Privacy from "./pages/Privacy";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<HomePage />}></Route>

      <Route path="/privacy" element={<Privacy />}></Route>

      <Route path="*" element={<ErrorPage />}></Route>
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
